// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { CoreSiteSchema } from '@services/sites';

/**
 * Database variables for CoreCourseModulePrefetchDelegate service.
 */
export const CHECK_UPDATES_TIMES_TABLE = 'check_updates_times';
export const SITE_SCHEMA: CoreSiteSchema = {
    name: 'CoreCourseModulePrefetchDelegate',
    version: 1,
    tables: [
        {
            name: CHECK_UPDATES_TIMES_TABLE,
            columns: [
                {
                    name: 'courseId',
                    type: 'INTEGER',
                    primaryKey: true,
                },
                {
                    name: 'time',
                    type: 'INTEGER',
                    notNull: true,
                },
            ],
        },
    ],
};

export type CoreCourseCheckUpdatesDBRecord = {
    courseId: number;
    time: number;
};
